import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Landing Page</h1>
      </header>
      <div className="content">
        <div className="text">
          <p>"An Education Obtained With Money, is Worse than no Education at all"</p>
          <p className="attribution">- Socrates</p>
        </div>
        <div className="image">
          <img src="Socretes.png" alt="Description of image" />
        </div>
      </div>
    </div>
  );
}

export default App;


